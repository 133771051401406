import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import Pagination from "../components/Pagination";
import Products from "../components/Products";
import Header from "../components/Header";
import Trendingproducts from "../components/Trendingproducts";
import { useProductData } from "../components/ProductsContextApi";

function Commodities() {
  const allprods = useProductData().allProductData;
  const products = allprods?.filter((product) => product.producttype == "2");
  // const [products, setProducts] = useState("");
  const [loading, setLoading] = useState(false);
  const [isGrid, setIsGrid] = useState(true);

  // const getAllProducts = () => {
  //   fetch(`https://ghmarkets.net/test/ghmarketconnect/?ghw=agric&svg=list&cat`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       //console.log(json['results'])
  //       setProducts(json["results"]);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getAllProducts();
  // }, []);

  /**
   * Pagination data starts here
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (data) => {
    let currentNumber = data.selected + 1;
    setCurrentPage(currentNumber);
  };
  //Pagination data ends here

  const toggleView = (viewType) => {
    setIsGrid(viewType);
  };

  return (
    <div>
      {/* <header>
        <Topbar />
        <div className="header-search-area style-two">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-3">
                <div className="logo">
                  <NavLink to="/">
                    <img src="assets/img/logo/w_logo.png" alt="" />
                  </NavLink>
                </div>
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="d-block d-sm-flex align-items-center justify-content-end">
                  <div className="header-search-wrap">
                    <form action="#">
                      <input type="text" placeholder="Search for product..." />
                      <select className="custom-select">
                        <option selected="">All Categories</option>
                        <option>Women's Clothing</option>
                        <option>Men's Clothing</option>
                        <option>Luggage & Bags</option>
                      </select>
                      <button>
                        <i className="fas fa-search"></i>
                      </button>
                    </form>
                  </div>
                  <div className="header-action">
                    <ul>
                      <li>
                        <NavLink to="#">
                          <i className="far fa-star"></i>Wishlist
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <i className="fas fa-redo"></i>Compare
                        </NavLink>
                      </li>
                      <li className="header-shop">
                        <NavLink to="#">
                          <i className="flaticon-shopping-bag"></i>Cart
                          <span className="cart-count">0</span>
                        </NavLink>
                      </li>
                      <li className="header-sine-in">
                        <NavLink to="contact.html">
                          <p>
                            Not yet a vendor? <span>Register Now</span>
                          </p>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="menu-area style-two">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo d-none">
                      <NavLink to="index-2.html">
                        <img src="assets/img/logo/w_logo.png" alt="" />
                      </NavLink>
                    </div>
                    <Leftmenu />
                    <Navbar />
                    <Livemarket />
                  </nav>
                </div>

                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className="nav-logo">
                      <NavLink to="index-2.html">
                        <img src="assets/img/logo/logo.png" alt="" title="" />
                      </NavLink>
                    </div>
                    <div className="menu-outer"></div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-twitter"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-facebook-f"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-pinterest-p"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-instagram"></span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#">
                            <span className="fab fa-youtube"></span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div>
      </header> */}

      <Header />

      <main>
        <div className="shop-area pt-90 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-8 col-sm-10 order-2 order-lg-0">
                <aside className="shop-sidebar">
                  <Trendingproducts>Trending Products</Trendingproducts>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap mb-35">
                  <div className="shop-top-left">
                    <h5 className="title">Groceries and Agric Commodities</h5>
                  </div>
                  <div className="shop-top-right">
                    <form action="#">
                      <label htmlFor="shortBy">All Commodities</label>
                      {/* <select id="shortBy" name="select" className="form-select" aria-label="Default select example">
                                            <option value="">Sorting</option>
                                            <option>Free Shipping</option>
                                            <option>Best Match</option>
                                            <option>Newest Item</option>
                                            <option>Size A - Z</option>
                                        </select> */}
                    </form>
                    {/* <ul>
                      <li>View</li>
                      <li className="active">
                        <NavLink to="">
                          <i className="fa-solid fa-table-cells"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <i className="fa-solid fa-bars"></i>
                        </NavLink>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="row">
                  <Products slicedProducts={currentPosts} loading={loading} view={isGrid} />
                </div>
                <div className="shop-bottom-wrap">
                  {products.length > 0 ? (
                    <div className="shop-bottom-top">
                      {/* <h5 className="title">Shop</h5> */}
                      <p>
                        Showing {indexOfFirstPost + 1} to{" "}
                        {indexOfLastPost > products.length
                          ? products.length
                          : indexOfLastPost}{" "}
                        of {products.length} Item(s)
                      </p>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={products.length}
                        paginate={paginate}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="shop-bottom-box">
                    <div className="shop-bottom-left">
                      <form action="#">
                        {/* <select id="short-By" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Show 09</option>
                                                <option>Show 12</option>
                                                <option>Show 08</option>
                                                <option>Show 06</option>
                                                <option>Show 03</option>
                                            </select> */}
                      </form>
                    </div>
                    <div className="shop-bottom-right">
                      <form action="#">
                        {/* <select id="short-by" name="select" className="form-select" aria-label="Default select example">
                                                <option value="">Default sorting</option>
                                                <option>Free Shipping</option>
                                                <option>Best Match</option>
                                                <option>Newest Item</option>
                                                <option>Size A - Z</option>
                                            </select> */}
                      </form>
                      <ul>
                        <li
                          className={isGrid && "active"}
                          onClick={() => {
                            toggleView(true);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-table-cells"></i>
                          </NavLink>
                        </li>
                        <li
                          className={isGrid || "active"}
                          onClick={() => {
                            toggleView(false);
                          }}
                        >
                          <NavLink to="#">
                            <i className="fa-solid fa-bars"></i>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Commodities;
